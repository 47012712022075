import React, { useEffect, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import LogoIMI from "../assets/imi-logo.png";
import "./EmailResponse.css";
import ActivatedImage from "../assets/verified-new.png";
import SuccessImg from "../assets/completed-new.png";

function SudahAktivasi() {
  return (
    <>
      <img src={LogoIMI} alt="imigrasi" className="logo-imigrasi" />
      <div className="heading-1">Email Sudah Diverifikasi</div>
      <div className="heading-2">
        Email kamu sudah pernah diverifikasi sebelumnya. Silahkan menikmati
        setiap layanan yang telah kami sediakan!
      </div>
      <img
        src={ActivatedImage}
        className="confirmed-image"
        alt="already-confirmed"
      />
      {/* <div>
        <a href={process.env.REACT_APP_INISA_URL}>
          <button className='button__link'>Kembali ke IMIGRASI</button>
        </a>
      </div> */}
    </>
  );
}

function SuksesAktivasi() {
  return (
    <>
      <img src={LogoIMI} alt="imigrasi" className="logo-imigrasi" />
      <div className="heading-1">Verifikasi Email Berhasil</div>
      <div className="heading-2">
        Sekarang kamu dapat mengakses seluruh layanan Digital Imigrasi yang
        telah disediakan. Selamat menikmati!
      </div>
      <img
        src={SuccessImg}
        className="confirmed-image"
        alt="already-confirmed"
      />
      {/* <div>
        <a href={process.env.REACT_APP_INISA_URL}>
          <button className='button__link'>Mulai Explore IMIGRASI</button>
        </a>
      </div> */}
    </>
  );
}

function EmailResponse(props) {
  const [responseData, setResponseData] = useState("");
  // const values =
  //   props.location.search && queryString.parse(props.location.search);
  const { token } = props.match.params;

  useEffect(() => {
    const validate = async () => {
      // let formdata = new FormData();
      // formdata.append("EmailKey", values.key);
      const formdata = {
        EmailKey: token,
      };

      const response = await axios
        .post(
          // `${process.env.REACT_APP_API_URL}access/email/validation`,
          `${process.env.REACT_APP_API_URL}access/v2/email/validation-platform`,
          formdata,
          {
            headers: {
              Accept: "application/json",
            },
          }
        )
        .then((res) => res)
        .catch((err) => err.response);
      // if (response.data.status === "success") setResponseData(response?.data);
      if (response.data.status === "error") setResponseData(response?.data);
    };
    validate();
  }, [token]);

  return (
    <>
      {token && (
        <div className="email-response">
          <div className="wrapper">
            {!responseData ? <SuksesAktivasi /> : <SudahAktivasi />}
          </div>
        </div>
      )}
    </>
  );
}

export default EmailResponse;
