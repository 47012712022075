import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import EmailResponse from "./pages/EmailResponse";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/:token"
          name="Email Response"
          render={(props) => <EmailResponse {...props} />}
        />
        <Redirect from="/" to="/:token" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
